<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar (producto, descripción o familia)"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        class="position-relative"
        hover
        responsive
        primary-key="producto"
        show-empty
        empty-text="No se encontraron registros coincidientes"
        :busy="tableIsBusy"
        :items="productsData"
        :fields="productsFields"
        :per-page="perPage"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <template #head(producto)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>

        <template #head(descrip)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>

        <template #head(familia)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>

        <template #head(umedida)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>

        <template #head(activo)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>

        <template #head(fechainv)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>

        <!-- Column: producto -->
        <template #cell(producto)="data">
          <b-link
            class="font-weight-bold text-info"
          >
            {{ data.item.producto }}
          </b-link>
        </template>

        <!-- Column: estado -->
        <template #cell(activo)="data">
          <b-badge
            :variant="(data.item.activo === '1') ? 'light-success' : 'light-danger'"
          >
            {{ (data.item.activo === '1') ? 'Activo' : 'Inactivo' }}
          </b-badge>
        </template>

        <!-- Column: creacion -->
        <template #cell(fechainv)="data">
          {{ data.item.fechainv.substring(0, 10) }}
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <!-- Pagination info -->
          <b-col
            v-if="productsMeta"
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando del {{ productsMeta.from }} al {{ productsMeta.to }} de {{ productsMeta.total }} registros</span>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="productsMeta"
              v-model="currentPage"
              :total-rows="productsMeta.total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>

</template>

<script>
import axios from '@axios'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },

  data() {
    return {
      searchQuery: '',

      sortField: 'idproductos',
      sortDesc: 'desc',

      perPage: 10,
      perPageOptions: [5, 10, 25, 50, 100, 'Todos'],
      currentPage: 1,

      productsFields: [
        { key: 'producto', label: 'Código' },
        { key: 'activo', label: 'Estado' },
        { key: 'descrip', label: 'Descripción' },
        { key: 'familia', label: 'Familia' },
        { key: 'umedida', label: 'Unidad' },
        { key: 'fechainv', label: 'Creado' },
      ],
      productsData: null,
      productsMeta: null,

      productId: 0,
      tableIsBusy: true,
    }
  },

  watch: {
    perPage() {
      this.tableIsBusy = true
      this.getProducts()
    },
    currentPage() {
      this.tableIsBusy = true
      this.getProducts()
    },
    searchQuery() {
      // esperar 1 segundo para no saturar el servidor
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.tableIsBusy = true
        this.getProducts()
      }, 1000)
    },
  },

  created() {
    this.getProducts()
  },

  methods: {
    getProducts() {
      if (this.perPage === 'Todos') {
        this.perPage = this.productsMeta.total
      }

      axios
        .get(`accounting-system/products?perPage=${this.perPage}&page=${this.currentPage}&query=${this.searchQuery}&sortField=${this.sortField}&sortDesc=${this.sortDesc}`)
        .then(response => {
          this.tableIsBusy = false
          this.productsMeta = response.data.meta
          this.productsData = response.data.data
        })
        .catch(error => {
          this.tableIsBusy = false
          this.showErrors(error)
        })
    },

    sortColumn(field) {
      if (this.sortField === field) {
        if (this.sortDesc === 'asc') {
          this.sortDesc = 'desc'
        } else {
          this.sortDesc = 'asc'
        }
      } else {
        this.sortField = field
        this.sortDesc = 'asc'
      }
      this.getProducts()
    },

    makeToast(variant = null, title = null, message = null) {
      this.$bvToast.toast(message, {
        title,
        variant,
        solid: true,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.pointer {
  cursor: pointer;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
